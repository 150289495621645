<template>
  <div class="scriptoriumDetails">
    <div v-if="loading || !scriptorium">
      <b-container fluid="xxl">
        <b-row class="justify-content-md-center">
            <b-col class="mt-5">
              <div class="spinner-border m-5" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-else>
      <b-container fluid="xxl">
        <b-row>
          <b-col class="pl-0">
            <PageTitle v-bind:title="scriptorium.name" :pretitle="'Scriptorium details'" />
          </b-col>
          <b-col>
            <div class="mt-5 text-right">
              <span v-if="editMode">
                <button @click="saveEdit" class="btn-legam-xs mb-2 btn-primary">
                  <b-icon icon="lock-fill"></b-icon>
                  Save changes
                </button>
                <button @click="cancelEdit" class="btn-legam-xs mb-2">
                  Cancel
                </button>
                <button v-if="$store.getters.checkRole('legam.bib.scriptorium.delete')" @click="deleteItem" class="btn-legam-xs mb-2 btn-danger">
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
              <span v-else>
                <button @click="editMode = true" v-if="$store.getters.checkRole('legam.bib.scriptorium.modify')" class="btn-legam-xs mb-2">
                  <b-icon icon="unlock-fill"></b-icon>
                  Unlock editing
                </button>
                <button
                  v-if="!$store.getters.checkRole('legam.bib.scriptorium.modify') && $store.getters.checkRole('legam.bib.scriptorium.delete')"
                  @click="deleteItem"
                  class="btn-legam-xs mb-2 btn-danger"
                >
                  <b-icon icon="trash2-fill"></b-icon>
                  Delete
                </button>
              </span>
            </div>
          </b-col>
        </b-row>

        <b-row class="justify-content-md-center">
          <b-col>
            <article>
              <b-row>
                <b-col>
                  <h6>Abbreviation</h6>
                  <b-form-input
                    id="name"
                    v-model="scriptorium.abbreviation"
                    placeholder="Abbreviation"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="scriptorium.abbreviation"></span>
                </b-col>
                <b-col>
                  <h6>Name</h6>
                  <b-form-input
                    id="name"
                    v-model="scriptorium.name"
                    placeholder="Name"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="scriptorium.name"></span>
                </b-col>
                <b-col>
                  <h6>Comment</h6>
                  <b-form-input
                    id="name"
                    v-model="scriptorium.comment"
                    placeholder="Comment"
                    v-if="editMode"
                  ></b-form-input>
                  <span v-else v-html="scriptorium.comment"></span>
                </b-col>
              </b-row>

              <hr>

              <b-row>
                <b-col>
                  <h6>Primary Location</h6>
                  <LocationSelect
                    v-if="editMode"
                    :location="scriptorium.location"
                    @select="selectLocation" />
                  <span v-else v-html="scriptorium.location ? scriptorium.location.display : ''"></span>
                </b-col>
                <b-col>
                  <h6>Scripta (Primary Scripta of Location)</h6>
                  <span v-html="scriptorium.location ? scriptorium.scripta : ''"></span>
                </b-col>
              </b-row>

              <hr>

              <div class="accordion" role="tablist">
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-1 class="text-left">
                      Documents [ certain ] <small>({{ certainMans.length }} {{ certainMans.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-1" accordion="my-accordion-1" role="tabpanel">
                    <b-card-body>
                      <!-- <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-manuscript>
                        <b-icon icon="plus"></b-icon>
                        Add
                      </span> -->
                      <b-modal id="modal-add-manuscript" title="Add manuscript" ok-only ok-variant="secondary" size="lg">
                        <ManuscriptAutocomplete @select="addManuscript" />
                      </b-modal>
                      <ScriptoriumManuscriptTable
                        :showTotal="true"
                        :manuscripts="certainMans"
                        @delete="deleteManuscript"
                        :editMode="editMode"
                        v-if="certainMans.length"
                      />
                      <span v-else>No data</span>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-2 class="text-left">
                      Documents [ uncertain ] <small>({{ uncertainMans.length }} {{ uncertainMans.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-2" accordion="my-accordion-2" role="tabpanel">
                    <b-card-body>
                      <!-- <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-2 mb-2 float-right" v-b-modal.modal-add-manuscript>
                        <b-icon icon="plus"></b-icon>
                        Add
                      </span> -->
                      <b-modal id="modal-add-manuscript" title="Add manuscript" ok-only ok-variant="secondary" size="lg">
                        <ManuscriptAutocomplete @select="addManuscript" />
                      </b-modal>
                      <ScriptoriumManuscriptTable
                        :showTotal="true"
                        :manuscripts="uncertainMans"
                        @delete="deleteManuscript"
                        :editMode="editMode"
                        v-if="uncertainMans.length"
                      />
                      <span v-else>No data</span>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body class="mb-1">
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-3 class="text-left">
                      Documents [ undefined ] <small>({{ undefinedMans.length }} {{ undefinedMans.length === 1 ? 'entry' : "entries" }})</small>
                      <span class="when-open float-right">-</span>
                      <span class="when-closed float-right">+</span>
                    </b-button>
                  </b-card-header>
                  <b-collapse id="accordion-3" accordion="my-accordion-3" role="tabpanel">
                    <b-card-body>
                      <!-- <span v-if="editMode" class="btn-legam-xs btn-legam-xxs ml-3 mb-3 float-right" v-b-modal.modal-add-manuscript>
                        <b-icon icon="plus"></b-icon>
                        Add
                      </span> -->
                      <b-modal id="modal-add-manuscript" title="Add manuscript" ok-only ok-variant="secondary" size="lg">
                        <ManuscriptAutocomplete @select="addManuscript" />
                      </b-modal>
                      <ScriptoriumManuscriptTable
                        :showTotal="true"
                        :manuscripts="undefinedMans"
                        @delete="deleteManuscript"
                        :editMode="editMode"
                        v-if="undefinedMans.length"
                      />
                      <span v-else>No data</span>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </article>
          </b-col>
        </b-row>
        <div class="mt-4" v-if="config.editable">
          <h6>Logs</h6>
          <ChangeLogsTable :objectType="'scriptorium'" :objectId="id" :key="'logs' + index" />
        </div>
      </b-container>
    </div>
  </div>
</template>

<style scoped>
  * >>> th {
    font-size: 10px !important;
    text-transform: uppercase;
    color: #bbbbbb;
  }
</style>

<script>
  import { mapState } from 'vuex'
  import router from '@/router'
  import PageTitle from '@/components/PageTitle.vue'
  import LocationSelect from '@/components/Main/LocationSelect.vue'
  import ChangeLogsTable from '@/components/ChangeLogsTable.vue'
  import ManuscriptAutocomplete from '@/components/Bibliography/ManuscriptAutocomplete.vue'
  import ScriptoriumManuscriptTable from '@/components/Bibliography/ScriptoriumManuscriptTable.vue'
  import config from '@/config.js'

  export default {
    name: 'ScriptoriumDetails',
    data() {
      return {
        id: this.$route.params.id,
        loading: true,
        editMode: false,
        index: 0,
        config: config,
      }
    },
    components: {
      PageTitle,
      ChangeLogsTable,
      ManuscriptAutocomplete,
      ScriptoriumManuscriptTable,
      LocationSelect,
    },
    computed: {
      ...mapState({
          scriptorium: state => state.scriptorium.scriptorium
      }),
      'certainMans': function() {
        if ('certain' in this.scriptorium.manuscripts) {
          return this.scriptorium.manuscripts['certain']
        } else {
          return []
        }
      },
      'uncertainMans': function() {
        if ('uncertain' in this.scriptorium.manuscripts) {
          return this.scriptorium.manuscripts['uncertain']
        } else {
          return []
        }
      },
      'undefinedMans': function() {
        if ('undefined' in this.scriptorium.manuscripts) {
          return this.scriptorium.manuscripts['undefined']
        } else {
          return []
        }
      }
    },
    mounted(){
      this.$store.dispatch('loadScriptorium', this.id)
    },
    watch: {
      'id'(){
        this.loading = true
        this.$store.dispatch('loadScriptorium', this.id)
      },
      'scriptorium'(){
        this.loading = false
      }
    },
    methods: {
      selectLocation(location){
        this.scriptorium.locationId = null
        this.scriptorium.location = null
        if (location) {
          this.scriptorium.locationId = location.id
          this.scriptorium.location = location
        }
      },
      cancelEdit(){
        this.editMode = false
        this.$store.dispatch('loadScriptorium', this.id)
        this.$store.dispatch('addNotification', {
          type: 'warning',
          text: `Changes dismissed`
        })
      },
      saveEdit(){
        this.editMode = false
        this.$store.dispatch('saveScriptorium', this.scriptorium).then(() => {
          this.$store.dispatch('addNotification', {
            type: 'success',
            text: `Changes saved`
          })
          this.index++
        })
      },
      deleteItem(){
        this.$bvModal.msgBoxConfirm('Are you sure?').then(value => {
          if (value){
            this.$store.dispatch('deleteScriptorium', this.scriptorium).then(() => {
              router.push('/scriptorium')
            })
          }
        })
      },

      addManuscript(manuscript){
        this.$bvModal.hide('modal-add-manuscript')
        this.$store.dispatch('addNotification', {
          type: 'success',
          text: `Manuscript added`
        })
        this.scriptorium.manuscripts.push(manuscript)
      },
      deleteManuscript(manuscriptId){
        this.scriptorium.manuscripts = this.scriptorium.manuscripts.filter(
          manuscript => manuscript.id != manuscriptId
        )
      },
    }
  }
</script>
