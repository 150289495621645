var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"clearfix"},[_c('b-table',{class:_vm.config.editable ? '' : 'not-editable',attrs:{"striped":"","small":"","hover":"","sort-icon-left":"","items":_vm.manuscriptsData,"per-page":_vm.perPage,"current-page":_vm.currentPage,"fields":_vm.fields},on:{"row-clicked":_vm.onRowClicked},scopedSlots:_vm._u([{key:"cell(edition)",fn:function(data){return [_c('router-link',{attrs:{"to":{
          name: 'DocumentDetails',
          params: {
            id: data.item._document.id,
          },
        }},domProps:{"innerHTML":_vm._s(data.item._document.sigle)}})]}},{key:"cell(codeRepcrit)",fn:function(data){return [_c('router-link',{attrs:{"to":{
          name: 'ManuscriptDetails',
          params: {
            id: data.item._object.id,
            slug: data.item._object.codeRepcritNormalized,
          },
        }},domProps:{"innerHTML":_vm._s(data.item._object.codeRepcrit)}})]}},{key:"cell(dateNum)",fn:function(data){return [_c('span',{domProps:{"innerHTML":_vm._s(data.item.dateNum)}})]}},{key:"cell(codeFull)",fn:function(data){return [_c('span',{domProps:{"innerHTML":_vm._s(data.item.codeFull)}})]}}])}),(_vm.showTotalLable)?_c('b-col',{staticClass:"float-left",attrs:{"sm":"4","md":"3"}},[_vm._v(" Total "+_vm._s(_vm.totalRows)+" "+_vm._s(_vm.totalRows == 1 ? "entry" : "entries")+" in table ")]):_vm._e(),(_vm.totalRows > 10)?_c('b-col',{staticClass:"float-right",attrs:{"sm":"3","md":"2"}},[_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"align":"fill","size":"sm","first-number":"","last-number":""},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }